




















































import { ChartData, combinedRate, RawDashboardData } from '@/includes/logic/dashboard/types'
import camelToUnderscoreCase from '@/assets/utils/camelToUnderscoreCase'

import LineChart from 'piramis-base-components/src/components/Charts/LineChart/lineChart.vue'

import { Component, Prop, Watch } from 'vue-property-decorator'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Vue from 'vue'
import { cloneDeep } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    LineChart,
    VuePerfectScrollbar
  }
})
export default class CombinedRates extends Vue {
  @Prop() rawData!: RawDashboardData

  loaded = false

  lineChartOptions = {
    chart: {
      sparkline: true,
      type: 'area',
      xAxis: {
        enabled: false
      },
      yAxis: {
        enabled: false
      },
    },
    showInfo: true,
    colors: [ 'rgba(var(--a-primary), 1)' ],
    stroke: {
      curve: 'smooth',
      width: 2.5
    },
    dataLabels: {
      fontSize: '8px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 400
    },
    tooltip: {
      fontSize: '14px',
      fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
      fontWeight: 700
    }
  }

  currentDayValue = {
    name: '',
    value: 0,
  }

  combinedRates: any = []

  @Watch('rawData')
  dataWatcher(data: RawDashboardData) {
    if (data) {
      let fields = {
        new_user_enter: [ ...data.new_user_enter ],
        new_user_kicked: [ ...data.new_user_kicked ],
        new_user_allowed: [ ...data.new_user_allowed ],

        manual_user_moderation: [ ...data.manual_user_moderation ],
        manual_admin_moderation: [ ...data.manual_admin_moderation ],
        trigger_punish: [ ...data.trigger_punish ],
        trigger_unpunish: [ ...data.trigger_unpunish ],

        facecontrol: [ ...data.facecontrol ],
        guard: [ ...data.guard ],

        commands: [ ...data.commands ],

        trigger_call: [ ...data.trigger_call ],
        reputation_call: [ ...data.reputation_call ],
      }
      this.combinedRates = this.getField(fields)
    }
  }

  getField(rawFields: { [key: string]: Array<combinedRate> }): Array<ChartData> {
    let arr: Array<ChartData> = []
    Object.entries(rawFields).forEach(([ key, rawField ]) => {
      let field = {
        total: 0,
        series: [
          {
            name: this.$t(`dashboard_statistics_${ camelToUnderscoreCase(key) }`).toString(),
            data: new Array(8).fill(0)
          }
        ],
        labels: cloneDeep(this.getDatesArr())
      }
      rawField.forEach(dataItem => {
        if (dataItem) {
          const dateIndex = field.labels.indexOf(dataItem.time)
          field.total += dataItem.value
          field.series[0].data[dateIndex] = dataItem.value
        }
      })
      if (field.total) {
        arr.push(field)
      }
    })
    return arr
  }

  getDatesArr() {
    const from = moment(this.rawData.period_start)
    const to = moment(this.rawData.period_end)
    let timeArr: Array<string> = []

    while (!from.isAfter(to)) {
      timeArr.push(from.format('YYYY-MM-DD'))
      from.add(1, 'day')
    }
    return timeArr
  }

  setValues(values: { name: string, value: number }): void {
    this.currentDayValue = values
  }
}
