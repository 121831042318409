














































import Vue from 'vue'
import { Component } from "vue-property-decorator";

@Component
export default class Diagnostic extends Vue {
  diagnosticFieldsBlackList = [ 'members_count_not_loaded' ]

  showDiagnosticField(fieldKey:string, value:boolean):boolean {
    return value && !this.diagnosticFieldsBlackList.includes(fieldKey);
  }

  get showDiagnostic() {
    return Object
      .entries(this.$store.state.chatState.chat.diagnostic)
      .some(([ key, value ]) => this.showDiagnosticField(key, value as boolean))
  }

  get botIsDisabled() {
    return !this.$store.state.chatState.chat.enabled
  }
}
