













import { InputSetups } from '@/mixins/input-setups'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class ChatMode extends Mixins<UseFields, InputSetups, TariffsTagsHelper>(UseFields, InputSetups, TariffsTagsHelper){
  get chatModeOptions():Array<SelectOptionData> {
    return [
      {
        label: this.$t('kick_all_users_default').toString(),
        value: false
      },
      {
        label: this.$t('kick_all_users_comments_mode').toString(),
        value: true,
        tags: this.getTagsByFieldKey('extended_reputation')!,
        disabled: !!this.getTagsByFieldKey('extended_reputation')
      },
    ]
  }
}
