





































import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { ChartData, combinedRate, RawDashboardData } from '@/includes/logic/dashboard/types'
import camelToUnderscoreCase from '@/assets/utils/camelToUnderscoreCase'
import { isStatEmpty } from '@/views/chat/dashboard/logic/isStatEmpty'
import { sumCombinedRatesValues } from '@/views/chat/dashboard/logic/sumCombinedRatesValues'
import { TagRowItem } from '@/components/TagRow/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    TagsRow,
    NewColumnChart,
    ConfigField,
    EmptyData
  },
  data() {
    return {
      isEmptyStat: isStatEmpty
    }
  }
})
export default class CommandStat extends Mixins<UseFields, TariffsTagsHelper>(UseFields, TariffsTagsHelper) {
  @Prop() rawData!: RawDashboardData

  commandsChartSeries: ChartData | null = null

  totalValues:Array<TagRowItem> = []

  loaded = false

  get columnOptions(): ColumnChartOptions {
    return {
      chart: {
        height: 200,
        zoom: false,
        margins: {
          bottom: 30
        }
      },
      xAxis: {
        visible: true,
        formatter: value => moment(value).format('MMM D')
      },
      yAxis: {
        visible: true,
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-danger), 1)', ],
      tooltip: {
        visible: true,
        colorTip: true
      },
    }
  }

  @Watch('rawData')
  fataWatcher(data: RawDashboardData) {
    if (data) {
      let fields = {
        commands: [ ...data.commands ],
      }
      this.commandsChartSeries = this.chartSeries(fields)
      this.calculateTotals(fields)
      this.loaded = false
    }
  }

  calculateTotals(rawFields: { [key: string]: Array<combinedRate> }):void {
    const commandsTotal = sumCombinedRatesValues(rawFields.commands)

    this.totalValues = [
      {
        title: 'dashboard_statistics_commands_total',
        value: commandsTotal,
        color: 'rgba(var(--a-primary), 1)',
        icon: 'alert',
      },
    ]
  }

  chartSeries(rawFields: { [key: string]: Array<combinedRate> }): ChartData {
    let field: ChartData = {
      total: 0,
      series: [],
      labels: cloneDeep(this.getDatesArr())
    }
    Object.entries(rawFields).forEach(([ key, rawField ]) => {
      let series = {
        name: this.$t(`dashboard_statistics_${ camelToUnderscoreCase(key) }`).toString(),
        data: new Array(8).fill(0)
      }
      rawField.forEach(dataItem => {
        if (dataItem) {
          const dateIndex = field.labels.indexOf(dataItem.time)
          series.data[dateIndex] = dataItem.value
        }
      })
      field.series.push(series)
    })
    return field
  }

  getDatesArr() {
    const from = moment(this.rawData.period_start)
    const to = moment(this.rawData.period_end)
    let timeArr: Array<string> = []

    while (!from.isAfter(to)) {
      timeArr.push(from.format('YYYY-MM-DD'))
      from.add(1, 'day')
    }
    return timeArr
  }

  mounted() {
    this.loaded = true
  }

}
