




































import TariffsTagsHelper from "../../../../mixins/TariffsTagsHelper";
import { ChartData, RawDashboardData } from '@/includes/logic/dashboard/types';
import { isStatEmpty } from "../logic/isStatEmpty";
import { TagRowItem } from '@/components/TagRow/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import { UseFields } from "piramis-base-components/src/components/Pi/index";
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'

import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import moment from "moment";

@Component({
  components: {
    TagsRow,
    ConfigField,
    NewColumnChart,
    EmptyData
  },
  data() {
    return {
      isStatEmpty
    }
  }
})
export default class ReputationUsers extends Mixins<UseFields, TariffsTagsHelper>(UseFields, TariffsTagsHelper) {
  @Prop() rawData!: RawDashboardData

  loaded = false

  reputationUsers: ChartData | null = null

  get columnOptions(): ColumnChartOptions {
    return {
      chart: {
        height: 200,
        zoom: false,
        margins: {
          bottom: 30
        }
      },
      xAxis: {
        visible: true,
        formatter: value => moment(value).format('MMM D')
      },
      yAxis: {
        visible: true,
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-success), 1)' ],
      tooltip: {
        visible: true,
        colorTip: true
      },
    }
  }

  totals: Array<TagRowItem> = []

  @Watch('rawData')
  dataWatcher(data: RawDashboardData) {
    if (data) {
      let whoGotTimes: { [key: string]: Array<number> } = {}
      let whoGiveTimes: { [key: string]: Array<number> } = {}

      this.getDatesArr().forEach(time => {
        whoGotTimes[time] = []
        whoGiveTimes[time] = []
      })

      data.reputation_users.forEach(dataItem => {
        if (dataItem) {
          const time: string = dataItem.time
          const actor: number = dataItem.actor_user_id
          const target: number = dataItem.target_user_id

          if (!whoGotTimes[time].includes(target)) {
            whoGotTimes[time].push(target)
          }
          if (!whoGiveTimes[time].includes(actor)) {
            whoGiveTimes[time].push(actor)
          }
        }
      })
      const whoGotSeries = Object.values(whoGotTimes).map((arr: Array<number>) => arr.length)
      const whoGiveSeries = Object.values(whoGiveTimes).map((arr: Array<number>) => arr.length)

      this.totals = [
        {
          title: 'dashboard_statistics_total_reputation_actors',
          value: whoGiveSeries.reduce((previousValue, currentValue) => previousValue + currentValue, 0),
          icon: 'export',
          color: 'rgb(var(--a-primary))'
        },
        {
          title: 'dashboard_statistics_total_reputation_targets',
          value: whoGotSeries.reduce((previousValue, currentValue) => previousValue + currentValue, 0),
          icon: 'import',
          color: 'rgb(var(--a-success))'
        }
      ]

      this.reputationUsers = {
        total: 0,
        series: [
          {
            name: this.$t(`dashboard_statistics_reputation_actors`).toString(),
            data: whoGiveSeries,
          },
          {
            name: this.$t(`dashboard_statistics_reputation_targets`).toString(),
            data: whoGotSeries
          },
        ],
        labels: cloneDeep(this.getDatesArr())
      }
    }
  }

  getDatesArr() {
    const from = moment(this.rawData.period_start);
    const to = moment(this.rawData.period_end);
    let timeArr: Array<string> = []

    while (!from.isAfter(to)) {
      timeArr.push(from.format("YYYY-MM-DD"));
      from.add(1, "day");
    }
    return timeArr
  }

}
