









































































































































import getAvatarSettings from '../logic/helpers'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { RawDashboardData } from '@/includes/logic/dashboard/types'
import { FilterTypes } from '@/views/chat/filters/FilterTypes'

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import LineChart from 'piramis-base-components/src/components/Charts/LineChart/lineChart.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'

@Component({
  components: {
    ConfigField,
    LineChart,
    NewColumnChart,
    Icon,
    EmptyData
  },
  data() {
    return {
      getAvatarSettings
    }
  }
})
export default class ActiveFiltersList extends Mixins<UseFields, TariffsTagsHelper>(UseFields, TariffsTagsHelper) {
  @Prop() rawData!: RawDashboardData

  currentDayValue: { name: string, value: Array<number> } = {
    name: '',
    value: [],
  }

  // barChart = {
  //   'chart': {
  //     'height': 150,
  //     'sparkline': true,
  //     'xAxis': {
  //       'enabled': false,
  //     },
  //     'yAxis': {
  //       'enabled': false
  //     },
  //   },
  //   'colors': [ 'rgba(var(--a-primary), 1)' ],
  //   'zoom': false,
  // }

  get columnOptions(): ColumnChartOptions {
    return {
      chart: {
        height: 150,
        zoom: false,
        margins: {
          bottom: 0
        }
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      colors: [ 'rgba(var(--a-primary), 1)', ],
      tooltip: {
        visible: true,
        colorTip: true
      },
    }
  }

  filters: any = null

  loaded = false

  @Watch('rawData')
  dataWatcher(data: RawDashboardData) {
    if (data) {
      this.loaded = true
      const stat: Array<any> = []

      Object.values(FilterTypes).forEach(filterKey => {
        if (this.$store.state.chatState.chat.config[filterKey].enabled && filterKey !== 'warn') {
          const filterData = new Array(8).fill(0)

          if (data.filters[filterKey]) {
            for (let [ time, value ] of Object.entries(data.filters[filterKey])) {
              const dateIndex = this.getDatesArr().indexOf(time)
              filterData[dateIndex] = value
            }
          }

          const filter = {
            type: filterKey,
            total: Object.values(filterData).reduce((a, b) => a + b),
            series: [
              {
                name: this.$t('filter_fired_times').toString(),
                data: filterData
              }
            ],
            labels: this.getDatesArr().slice()
          }

          stat.push([ filterKey, filter ])
        }
      })

      this.filters = Object.fromEntries(stat.sort((a, b) => b[1].total - a[1].total))
      this.loaded = false
    }
  }

  setValues(values: { name: string, value: Array<number> }): void {
    this.currentDayValue = values
  }

  getFilterParams(filter: any): any {
    let params = {
      ban_type: this.$store.state.chatState.chat.config[filter.type].ban_type,
      first_message_ban_type: this.$store.state.chatState.chat.config[filter.type].first_message_ban_type,
      remove: this.$store.state.chatState.chat.config[filter.type].remove
    }

    let arr = []

    if (params.remove) {
      arr.push({
        color: 'text-danger',
        title: this.$t('filter_tooltip_delete_title'),
        icon: 'icon-delete'
      })
    }
    if (params.first_message_ban_type === 'none') {
      arr.push({
        color: 'text-success',
        title: this.$t('filter_tooltip_newbie_allowed_title'),
        icon: 'icon-check-circle'
      })
    }
    if (params.ban_type !== 'none') {
      arr.push({
        color: 'text-warning',
        title: this.$t('filter_tooltip_punish_title'),
        icon: 'icon-cloud-lightning'
      })
    }
    return arr
  }

  getDatesArr() {
    const from = moment(this.rawData.period_start)
    const to = moment(this.rawData.period_end)
    let timeArr: Array<string> = []

    while (!from.isAfter(to)) {
      timeArr.push(from.format('YYYY-MM-DD'))
      from.add(1, 'day')
    }
    return timeArr
  }

  gotoFilter(filterName:string):void {
    this.$router.push({ name: 'filter', params: { filterName } })
  }

}
