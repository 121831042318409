








































import { TriggersRawData } from '@/views/chat/dashboard/logic/types'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { RawDashboardData } from '@/includes/logic/dashboard/types'
import VuTriggers from '@/includes/logic/VuTriggers'
import { isStatEmpty } from '@/views/chat/dashboard/logic/isStatEmpty';
import { TagRowItem } from '@/components/TagRow/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    TagsRow,
    ConfigField,
    Tags,
    EmptyData,
    NewColumnChart
  },
  data() {
    return {
      isEmptyStat: isStatEmpty
    }
  }
})
export default class ActiveUsersPerDay extends Mixins<UseFields, TariffsTagsHelper>(UseFields, TariffsTagsHelper) {
  @Prop() rawData!: RawDashboardData

  @Prop() triggersData!: TriggersRawData

  loaded = false

  topTrigger = null

  triggersStatistics: any = null

  get columnOptions(): ColumnChartOptions {
    return {
      chart: {
        height: 200,
        zoom: false,
        margins: {
          bottom: 30
        }
      },
      xAxis: {
        visible: true,
        formatter: value => moment(value).format('MMM D')
      },
      yAxis: {
        visible: true,
      },
      colors: [ 'rgba(var(--a-success), 1)' ],
      tooltip: {
        visible: true,
        colorTip: true
      },
    }
  }

  @Watch('triggersData')
  trigDataWatcher(data: TriggersRawData) {
    if (data) {
      let labels = this.getDatesArr(data)
      this.getTopTrigger(data)
      let chart = {
        total: 0,
        series: [ {
          data: new Array(labels.length).fill(0),
          name: this.$t('dashboard_statistics_triggers_executions')
        } ],
        labels: this.getDatesArr(data)
      }
      data.triggers_stat.forEach(trigger => {
        const dateIndex = labels.indexOf(moment(trigger.time).format('YYYY-MM-DD'))
        chart.series[0].data[dateIndex] += trigger.count
        chart.total += trigger.count
      })
      this.triggersStatistics = chart
    }
  }

  totalValues: Array<TagRowItem> = []

  get calculateTotals():Array<TagRowItem> {
    return [
      {
        title: 'dashboard_statistics_triggers_active_triggers_count',
        value: this.$store.state.chatState.chat.triggers.length,
        color: 'rgba(var(--a-primary), 1)',
        icon: 'clock-circle',
      },
      {
        title: 'dashboard_statistics_triggers_execution_times',
        value: this.triggersStatistics.total,
        color: 'rgba(var(--a-success), 1)',
        icon: 'check',
      },
      {
        title: 'dashboard_statistics_triggers_top_trigger',
        value: `«${ this.topTrigger }» 🥇`,
      },
    ]
  }

  getTopTrigger(data: TriggersRawData) {
    let sortTriggers = data.triggers_stat.sort((a, b) => b.count - a.count)
    if (!this.$store.state.triggersState.frontendTriggers) {
      VuTriggers.updateTriggers().then(() => {
        this.topTrigger = sortTriggers[0] ? this.$store.state.triggersState.frontendTriggers.find((item: any) => item.id === sortTriggers[0].trigger).name : null
      })
    } else {
      this.topTrigger = sortTriggers[0] ? this.$store.state.triggersState.frontendTriggers.find((item: any) => item.id === sortTriggers[0].trigger).name : null
    }
  }

  getDatesArr(rawData: any) {
    const from = moment(rawData.period_start)
    const to = moment(rawData.period_end)
    let timeArr: Array<string> = []

    while (!from.isAfter(to)) {
      timeArr.push(from.format('YYYY-MM-DD'))
      from.add(1, 'day')
    }
    return timeArr
  }

}
