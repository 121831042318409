



































import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { ChartData, RawDashboardData } from "@/includes/logic/dashboard/types";
import { isStatEmpty } from "../logic/isStatEmpty";
import { TagRowItem } from '@/components/TagRow/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";
import NewColumnChart from 'piramis-base-components/src/components/NewCharts/components/NewColumnChart.vue'
import { ColumnChartOptions } from 'piramis-base-components/src/components/NewCharts/types/ColumnOptions'

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from "lodash";
import moment from "moment";

@Component({
  components: {
    TagsRow,
    NewColumnChart,
    ConfigField,
    EmptyData
  },
  data() {
    return {
      isStatEmpty
    }
  }
})
export default class ActiveUsers extends Mixins<UseFields, TariffsTagsHelper>(UseFields, TariffsTagsHelper) {
  @Prop() rawData!: RawDashboardData

  loaded = false

  activeUsers: ChartData | null = null

  get columnOptions(): ColumnChartOptions {
    return {
      chart: {
        height: 200,
        zoom: false,
        margins: {
          bottom: 30
        }
      },
      xAxis: {
        visible: true,
        formatter: value => moment(value).format('MMM D')
      },
      yAxis: {
        visible: true,
      },
      colors: [ 'rgba(var(--a-primary), 1)' ],
      tooltip: {
        visible: true,
        colorTip: true
      },
    }
  }

  totals: Array<TagRowItem> = []

  @Watch('rawData')
  dataWatcher(data: RawDashboardData) {
    if (data) {
      const arr = new Array(8).fill(0);

      data.active_users.forEach(user => {
        let dateIndex = this.getDatesArr().indexOf(user.time);
        arr[dateIndex] += 1;
      });

      this.totals = [
        {
          value: arr.reduce((previousValue, currentValue) => previousValue + currentValue, 0),
          title: 'dashboard_statistics_total_active_users',
          color: 'rgb(var(--a-primary))',
          icon: 'thunderbolt'
        }
      ]

      this.activeUsers = {
        series: [
          {
            name: this.$t('dashboard_statistics_active_users').toString(),
            data: arr
          }
        ],
        labels: cloneDeep(this.getDatesArr())
      };
    }
  }

  getDatesArr() {
    const from = moment(this.rawData.period_start);
    const to = moment(this.rawData.period_end);
    let timeArr: Array<string> = []

    while (!from.isAfter(to)) {
      timeArr.push(from.format("YYYY-MM-DD"));
      from.add(1, "day");
    }
    return timeArr
  }

}
