

































































































































































import ChatLicense from '@/includes/logic/ChatLicense'
import ChatMode from '@/components/chat/ChatMode.vue'
import { InputSetups } from '@/mixins/input-setups'

import getVSelectOptionByValue from 'piramis-js-utils/lib/getVSelectOptionByValue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import LanguagePicker from 'piramis-base-components/src/components/LanguagePicker.vue'
import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'

import { Component, Mixins } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { upperFirst } from 'lodash'

@Component({
  components: {
    ChatMode,
    AListCustom,
    AListCustomItem,
    LanguagePicker
  },
  data() {
    return {
      timezoneList,
      upperFirst
    }
  },
})
export default class DetailedInformationAboutTheChat extends Mixins(UseFields, InputSetups, ClipboardCopyMixin) {
  chatLicense: ChatLicense = new ChatLicense(this.$store.state.chatState.chat)

  buyLink: Location = {
    'name': 'buy',
    'params': { [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id },
  }

  get buyButtonType():string {
    if (this.chatLicense.isLicenseExpired || this.chatLicense.expiresSoon) {
      return 'danger'
    }

    return 'default'
  }

  get buyLicenseButtonTitle():string {
    if (this.chatLicense.isLicenseExpired) {
      return this.$t('button_license_buy').toString()
    } else {
      return this.$t('button_license_prolongate').toString()
    }
  }

  get currentLicenseStatus():string {
    if (!this.chatLicense.isLicenseExpired) {
      return this.$t('license_status_active', [ upperFirst(this.chatLicense.licenseType) ]).toString()
    }

    if (this.chatLicense.licenseExpiresAt === '') {
      return this.$t('license_status_not_exists').toString()
    }

    return this.$t('license_status_expired').toString()
  }

  get licenseTitle(): string {
    if (this.chatLicense.isLicenseExpired) {
      if (this.chatLicense.licenseExpiresAt === '') {
        return this.$t('license_buy').toString()
      }

      if (this.chatLicense.licenseExpiresAt !== '') {
        return this.$t('license_expired').toString()
      }
    }

    return this.$t('license_expires_at').toString()
  }

  get timezone(): string {
    return getVSelectOptionByValue(timezoneList, this.$store.state.chatState.chat.timezone)
  }

  set timezone({ value }: any) {
    this.$store.commit('setObjectField', {
      object: this.$store.state.chatState.chat,
      objectPath: 'timezone',
      value: value,
    })
  }

  get chatVisibilityButtonTitle(): string {
    return this.$store.state.chatState.chat.hidden ? this.$t('show_chat').toString() : this.$t('hide_chat').toString()
  }

  get silentForUsersButtonTitle(): string {
    return this.$store.state.chatState.chat.silent_for_users ? this.$t('silent_mode_disable').toString() : this.$t('silent_mode_enable').toString()
  }

  get silentForAllButtonTitle(): string {
    return this.$store.state.chatState.chat.silent_for_all ? this.$t('silent_mode_for_all_disable').toString() : this.$t('silent_mode_for_all_enable').toString()
  }

  get journalButtonTitle():string {
    return this.$store.state.chatState.chat.log_channel_id ? this.$t('dellog_journal').toString() : this.$t('log_journal').toString()
  }

  handleLocaleChange(locale: 'ru' | 'en'): void {
    this.$store.commit('updateChatLang', locale)
  }

  journalButtonClick():void {
    if (this.$store.state.chatState.chat.log_channel_id) {
      this.$store.dispatch('runChatAction', 'resetlogchannel')
    } else {
      this.$router.push({ name: 'journal' })
    }
  }

  getTimezoneList(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(timezoneList)
  }

  goToChatCatalogSettings(): void {
    this.$router.push({
      name: 'catalog_integration'
    })
  }

  getPiramisSpamDetectionModes(): Promise<Array<SelectOptionData>> {
    let items: Array<SelectOptionData> = [
      { 'label': String(this.$t('piramis_spam_detection_modes_none')), 'value': 'None' },
      { 'label': String(this.$t('piramis_spam_detection_modes_log')), 'value': 'Log' },
      { 'label': String(this.$t('piramis_spam_detection_modes_ban')), 'value': 'Ban' }
    ]

    return Promise.resolve(items)
  }
}
