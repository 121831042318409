





































































































import TopActiveUsers from './components/topActiveUsers.vue'
import CombinedRates from './components/CombinedRates.vue'
import UsersStat from './components/UsersStat.vue'
import ActiveUsers from './components/ActiveUsers.vue'
import ReputationUsers from './components/reputationUsers.vue'
import ActiveFiltersList from './components/ActiveFiltersList.vue'
import Api from '@/includes/logic/Api'
import DetailedInformationAboutTheChat
  from '@/components/DetailedInformationAboutTheChat/DetailedInformationAboutTheChat.vue'
import Diagnostic from './components/Diagnostic.vue'
import { StatObject, topTrigger } from './logic/types'
import TriggersFiring from '@/views/chat/dashboard/components/TriggersFiring.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { RawDashboardData } from '@/includes/logic/dashboard/types'
import PageTitle from '@/components/PageTitle.vue'
import { errorNotification } from '@/includes/NotificationService'
import BotIsNotAdminMixin from "@/mixins/BotIsNotAdminMixin";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'

import { Component, Mixins, Ref } from 'vue-property-decorator'
import ModerationStat from '@/views/chat/dashboard/components/ModerationStat.vue'
import BlockingStat from '@/views/chat/dashboard/components/BlockingStat.vue'
import ReputationTriggerCallStat from '@/views/chat/dashboard/components/ReputationTriggerCallStat.vue'
import CommandStat from '@/views/chat/dashboard/components/CommandStat.vue'
import WindowResizeMixin from 'piramis-base-components/src/logic/helpers/WindowResizeMixin'

@Component({
  components: {
    CommandStat,
    ReputationTriggerCallStat,
    BlockingStat,
    ModerationStat,
    TriggersFiring,
    TopActiveUsers,
    Diagnostic,
    DetailedInformationAboutTheChat,
    CombinedRates,
    UsersStat,
    ActiveUsers,
    ActiveFiltersList,
    Tags,
    ReputationUsers,
    PageTitle
  },
})
export default class NewDashboard extends Mixins<UseFields, TariffsTagsHelper, BotIsNotAdminMixin, WindowResizeMixin>(UseFields, TariffsTagsHelper, BotIsNotAdminMixin, WindowResizeMixin) {
  loaded = false

  totalActiveUsers: Array<any> = []

  rawDashboardData: RawDashboardData | null = null

  rawTriggersData: (StatObject & topTrigger) | null = null

  topUsersHeight = 0

  @Ref('chatInfo') chatInfo!: HTMLElement

  @Ref('topActiveUsers') topActiveUsers!: HTMLElement

  getMaxHeight(): any {
    if (this.chatInfo) {
      if (!this.topUsersHeight) {
        this.topUsersHeight = this.chatInfo.clientHeight
      }
      return { height: `${ this.topUsersHeight }px` }
    }
  }

  mounted() {
    this.$nextTick(() => {
      const observer = new ResizeObserver(entries => {
        if (this.topActiveUsers) {
          this.topActiveUsers.style.height = `${ entries[0].contentRect.height }px`
        }
      })

      observer.observe(this.chatInfo.firstChild as HTMLElement);
      this.topActiveUsers.style.height = `${ (this.chatInfo.firstChild as HTMLElement).clientHeight }px`
    })
  }

  created() {
    this.loaded = true
    let triggersLoaded = false

    Api.getDashboardStat('tg', { chat_id: this.$store.state.chatState.chat.chat_id }).then(res => {
      this.rawDashboardData = res.data
      this.loaded = false
    })

    if (!this.getTagsByFieldKey('dashboard')) {
      triggersLoaded = true
      Api.getChatTriggersStat('tg', { chat_id: this.$store.state.chatState.chat.chat_id }).then(res => {
        this.rawTriggersData = res.data
      })
        .catch(errorNotification)
        .finally(() => {
          this.loaded = false
          triggersLoaded = false
        })
    }
  }
}
