
































































import getNoun from '@/assets/utils/getNoun'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { RawDashboardData } from '@/includes/logic/dashboard/types'
import Api from '@/includes/logic/Api'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    UserMainInfo,
    ConfigField,
    Tags,
    EmptyData
  },
  data() {
    return {
      getNoun,
      EntityTypes
    }
  }
})
export default class TopActiveUsers extends Mixins(TariffsTagsHelper) {
  @Prop({ default: null }) rawData!: RawDashboardData | null

  loaded = false

  users: Array<{ user: string, total: number }> = []

  title(userData:any):string {
    return userData.total + ' ' + getNoun(userData.total, this.$t('message_noun_1'),  this.$t('message_noun_2'),  this.$t('message_noun_5'))
  }

  @Watch('rawData')
  dataWatcher(data: RawDashboardData) {
    if (data && !this.getTagsByFieldKey('dashboard')) {
      this.loaded = true
      let entries = Object.entries(data.total_active_users)

      if (entries.length) {
        let users = entries.sort((a, b) => b[1] - a[1])
        Api.getUsersStat('tg', {
          chat_id: this.$store.state.chatState.chat.chat_id,
          user_ids: users.map(user => user[0]).slice(0, 10)
        }).then(res => {
          users.forEach(([ userId, total ]: [string, number], index) => {
            if (index < 10) {
              this.users.push({
                user: res.data.users[userId],
                total: total,
              })
            }
          })
        }).finally(() => {
          this.loaded = false
        })
      } else {
        this.loaded = false
      }
    }
  }

  topAliasByIndex(index:number):string {
    if (index === 0) {
      return '🥇'
    }
    if (index === 1) {
      return '🥈'
    }
    if (index === 2) {
      return '🥉'
    }

    return `${ index + 1 }.`
  }
}
