export default function getAvatarSettings(key: string): { color: string, icon: string } {
  let params = {
    icon: '',
    color: ''
  }
  if (key === "audio") {
    params.icon = "audiotrack"
    params.color = "primary"
  }

  if (key === "any_message") {
    params.icon = "surround_sound";
    params.color = "primary";
  }

  if (key === "bot_command") {
    params.icon = "developer_mode";
    params.color = "dark";
  }

  if (key === "bot_reply") {
    params.icon = "android";
    params.color = "primary";
  }

  if (key === "caps_message") {
    params.icon = "format_size";
    params.color = "danger";
  }

  if (key === "censor_word") {
    params.icon = "announcement";
    params.color = "danger";
  }

  if (key === "channel_reply") {
    params.icon = "forum";
    params.color = "primary";
  }

  if (key === "channel_message") {
    params.icon = "sensors";
    params.color = "primary";
  }

  if (key === "chat_reply") {
    params.icon = "chat";
    params.color = "success";
  }

  if (key === "contact") {
    params.icon = "contacts";
    params.color = "dark";
  }

  if (key === "dice") {
    params.icon = "casino";
    params.color = "dark";
  }

  if (key === "email_links") {
    params.icon = "email";
    params.color = "rgb(105, 106, 110)";
  }

  if (key === "files") {
    params.icon = "insert_drive_file";
    params.color = "primary";
  }

  if (key === "flood") {
    params.icon = "speaker_notes_off";
    params.color = "warning";
  }

  if (key === "gif") {
    params.icon = "gif";
    params.color = "dark";
  }

  if (key === "hieroglyph_message") {
    params.icon = "translate";
    params.color = "primary";
  }

  if (key === "images") {
    params.icon = "photo";
    params.color = "success";
  }

  if (key === "message_length") {
    params.icon = "linear_scale";
    params.color = "primary";
  }

  if (key === "permitted_word") {
    params.icon = "assignment";
    params.color = "danger";
  }

  if (key === "referral") {
    params.icon = "how_to_reg";
    params.color = "dark";
  }

  if (key === "regular_expression") {
    params.icon = "spellcheck";
    params.color = "danger";
  }

  if (key === "rtl_message") {
    params.icon = "format_textdirection_r_to_l";
    params.color = "danger";
  }

  if (key === "self_links") {
    params.icon = "near_me";
    params.color = "primary";
  }

  if (key === "sticker") {
    params.icon = "insert_emoticon";
    params.color = "#fbcb47";
  }

  if (key === "stop_word") {
    params.icon = "remove_circle";
    params.color = "danger";
  }

  if (key === "video") {
    params.icon = "videocam";
    params.color = "primary";
  }

  if (key === "video_note") {
    params.icon = "voice_chat";
    params.color = "primary";
  }

  if (key === "voice") {
    params.icon = "record_voice_over";
    params.color = "primary";
  }

  if (key === "warn") {
    params.icon = "warning";
    params.color = "warning";
  }

  if (key === "web_links") {
    params.icon = "insert_link";
    params.color = "dark";
  }

  if (key === "sticker_pack") {
    params.icon = "sentiment_satisfied_alt";
    params.color = "primary";
  }

  if (key === "emoji") {
    params.icon = "emoji_emotions";
    params.color = "primary";
  }

  if (key === "spoiler") {
    params.icon = "auto_awesome";
    params.color = "primary";
  }

  return params
}
